exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-devices-js": () => import("./../../../src/pages/devices.js" /* webpackChunkName: "component---src-pages-devices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-materials-js": () => import("./../../../src/pages/materials.js" /* webpackChunkName: "component---src-pages-materials-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-brand-js": () => import("./../../../src/templates/brand.js" /* webpackChunkName: "component---src-templates-brand-js" */),
  "component---src-templates-device-js": () => import("./../../../src/templates/device.js" /* webpackChunkName: "component---src-templates-device-js" */),
  "component---src-templates-material-js": () => import("./../../../src/templates/material.js" /* webpackChunkName: "component---src-templates-material-js" */)
}

